<template>
  <div class="service main page__view">
    <h2 class="header-text">{{ service.title }}</h2>
    <img
      height="400"
      :src="service.image"
      :alt="service.title"
      class="service__image"
    />
    <p class="service__description">
      {{ service.description }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['getServiceById']),
    service() {
      return this.getServiceById(this.$route.params.serviceId);
    },
  },
};
</script>

<style scoped>
.service__description {
  font-size: 1.3em;
  margin-top: 20px;
}
.service__image {
  margin-top: 20px;
  width: 100%;
  height: 400px;
  object-fit: cover;
}
</style>
